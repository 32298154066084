import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './App.css'; // Make sure to create or modify your CSS file

function App() {
  return (
    <GoogleOAuthProvider clientId="648929573308-rs2l3f7av6qf5vfu57kkah59941vfjhg.apps.googleusercontent.com">
      <div className="login-container">
        <h1>Welcome to Our App</h1>
        <GoogleLogin
          onSuccess={credentialResponse => {
            console.log('Login Success:', credentialResponse);
            // Send the response to the PHP endpoint
            fetch('https://www.knowplace.co.uk/api/validate_user.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(credentialResponse),
            })
              .then(response => response.json())
              .then(data => {
                if (data.valid) {
                  alert('User validated!');
                } else {
                  alert('User not found.');
                }
              })
              .catch(error => {
                console.error('Error validating user:', error);
              });
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
